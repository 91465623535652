import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Column}                             from "primereact/column";
import {DataTable}                          from "primereact/datatable";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import {BreadCrumb} from "primereact/breadcrumb";
import {Toolbar} from "primereact/toolbar";
import {CabecalhoTabela, Container, Tela} from "../../styles/global";

const RegistrarPonto = () => {
    const classe = 'Ponto';
    const tabela = 'pontos';
    const endpointAPI = 'cadastro/ponto';
    const aviso = useRef(null);
    const usuarioInicial = {id: 0, email: '', senha: ''};
    const [usuario, setUsuario] = useState(usuarioInicial);
    const [apresentarFormulario, setApresentarFormulario] = useState(true);
    const [apresentarPergunta, setApresentarPergunta] = useState(false);
    const [pontos, setPontos] = useState(null);
    const parametros = {
        tabelaDados      : {
            caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Registro de Pontos Diário'}],
            colunas: [
                {legenda: 'Colaborador', campo: 'usuario_nome'},
                {legenda: 'Data Entrada', campo: 'data_entrada', ordenavel: true},
                {legenda: 'Data Saída', campo: 'data_saida', ordenavel: true},
            ]
        }
    }
    const [parametrosTela, setParametrosTela] = useState(parametros);
    const iconeHome = {icon: 'pi pi-home'};

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || e.value || '';
        let _usuario = {...usuario};
        _usuario[`${propriedade}`] = texto;
        setUsuario(_usuario);
    };

    const limparTela = () => {
        setUsuario(usuarioInicial);
        setPontos(null);
        setApresentarFormulario(true);
    };

    const aoSalvar = async () => {
        try {
            if (!usuario.email || !usuario.senha) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Preencha suas credenciais!'};
            }

            await new api().requisitar('post', `${endpointAPI}/autenticar-colaborador`, usuario).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                setPontos(resposta.pontos);
                let _usuario = {...usuario};
                _usuario.id = resposta.idusuario;
                setUsuario(_usuario);
                setApresentarFormulario(false);
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const confirmacaoRegistro = async () => {
        confirmDialog({
            message:         `Deseja realmente registrar ponto?`,
            header:          'Atenção',
            icon:            'pi pi-exclamation-triangle',
            acceptLabel:     'Sim',
            acceptClassName: 'p-button-btxpp',
            rejectLabel:     'Não',
            rejectClassName: 'p-button-btxpp p-button-outlined',
            accept:          () => {
                registrarPonto();
            }
        });
    }
    const registrarPonto = async () =>{
        try {
            if (!usuario.email || !usuario.senha) {
                limparTela();
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Usuário não pode ser validado!'};
            }

            await new api().requisitar('post', `${endpointAPI}/registrar-ponto`, usuario).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                setPontos(resposta.pontos);
            }).catch((erro) => {
                limparTela();
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const botoesEsquerdaTabela = () => {
        return (<>
            { usuario.id > 0 ?
                <>
                    <Button label="Desconectar" className="p-button-danger mr-2 mt-2" onClick={limparTela}/>
                    <Button label="Registrar Ponto" icon="pi pi-plus" className="p-button-info mr-2 mt-2" onClick={confirmacaoRegistro}/>
                </>
                :
                <Button label="Login Colaborador" className="p-button-success mr-2 mt-2" onClick={limparTela}/>
            }
        </>);
    };

    return (
        <Container>
            <Toast ref={aviso}/>
            <BreadCrumb model={parametrosTela.tabelaDados.caminhoBreadcrumb} home={iconeHome}/>
            <Tela>
                <Toolbar left={botoesEsquerdaTabela}/>
                <DataTable paginator className="datatable-sm" removableSort dataKey="id" responsiveLayout="stack" breakpoint="575px"
                    rows={10} rowsPerPageOptions={[5, 10, 25, 100, 1000]} emptyMessage=" "
                    value={pontos}
                >
                    {parametrosTela.tabelaDados.colunas.map((coluna) => {
                        if (coluna.rel) {
                            return <Column key={coluna.campo} field={coluna.campo} header={coluna.legenda} body={(rowdata) => {
                                return (<span>{coluna.rel[rowdata[coluna.campo]]}</span>)
                            }} sortable={coluna.ordenavel}></Column>
                        }
                        return <Column key={coluna.campo} field={coluna.campo} header={coluna.legenda} sortable={coluna.ordenavel}></Column>
                    })}
                </DataTable>
                <Toast ref={aviso}></Toast>
                <Dialog className='fluid' header="Autenticação de Colaborador" style={{maxWidth: '90%', width: '800px'}} modal={true} visible={apresentarFormulario} onHide={(e) => setApresentarFormulario(false)}>
                    <div className="grid formgrid">
                        <div className="field col-12">
                            <label htmlFor="email">E-mail Colaborador<Obrigatorio>*</Obrigatorio> </label><br/>
                            <InputText className="w-full" autoComplete="none" id="email" maxLength={200} value={usuario.email} onChange={(e) => aoDigitar(e, 'email')} required/>
                        </div>
                    </div>
                    <div className="grid formgrid">
                        <div className="field col-12">
                            <label htmlFor="senha">Senha<Obrigatorio>*</Obrigatorio> </label><br/>
                            <InputText className="w-full" autoComplete="none" id="senha" type="password" maxLength={200} value={usuario.senha} onChange={(e) => aoDigitar(e, 'senha')} required/>
                        </div>
                    </div>
                    <div className="flex align-items-center gap-2">
                        <Button label="Entrar" onClick={aoSalvar} text className={'botao'}></Button>
                    </div>
                </Dialog>
            </Tela>
        </Container>
    );
}

export default RegistrarPonto;
