import styled   from "styled-components";


export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
`;

export const Tela = styled.div`
	padding: 2rem;
	border-radius: 4px;
	margin-bottom: 2rem;
	width: 30vw;
	min-width: 300px;
	max-width: 576px;
    max-height: 526.81px;
`;
