export default function debug(...params) {
    if (typeof window !== 'undefined') {
        if (window.localStorage.getItem('debugmode') === 'console' || (process.env.NODE_ENV === 'development' && window.localStorage.getItem('debugmode') !== 'alert' && window.localStorage.getItem('debugmode') !== 'both')) {
            console.log(...params);
        } else if (window.localStorage.getItem('debugmode') === 'alert') {
            alert(JSON.stringify(...params));
        } else if (window.localStorage.getItem('debugmode') === 'both') {
            console.log(...params);
            alert(JSON.stringify(...params));
        }
    }
}
