import { LogLevel }                                              from "@azure/msal-browser";
import {ptiDefaultRefreshToken, ptiDefaultToken, RegistrarToken} from "./auth";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: "65596f61-014a-44bb-854b-fb6ad3f63aca",
		authority: "https://login.microsoftonline.com/f01ca476-5f3d-4dd1-9f22-37d0c4946be3",
		redirectUri: "https://painelshowroom.pti.org.br/sistema",
		postLogoutRedirectUri: "https://painelshowroom.pti.org.br/logout"
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: isIE || isEdge || isFirefox
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
			}
		}
	}
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
	scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
