import React, {useEffect, useRef, useState} from 'react';
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Toast}                              from "primereact/toast";
import {BreadCrumb}                         from "primereact/breadcrumb";
import {Toolbar}                            from "primereact/toolbar";
import {Card}                               from "primereact/card";
import {DataTable}                          from "primereact/datatable";
import {FilterMatchMode, FilterOperator}    from 'primereact/api';
import {Column}                             from "primereact/column";
import {Dialog}                             from "primereact/dialog";
import {DataView}                           from "primereact/dataview";
import { ProgressSpinner }                  from 'primereact/progressspinner';

import {CabecalhoTabela, Container, Tela} from "../styles/global";
import api                                from "../services/api";
import Texto                              from "../services/texto";

const Tabela = ({
					parametros,
					listar,
					children,
				}) => {
	const iconeHome = {icon: 'pi pi-home'};
	const aviso = useRef(null);
	const grid = useRef(null);
	const textos = new Texto();
	const [pesquisaGeral, setPesquisaGeral] = useState(null);
	const [qtdeItens, setQtdeItens] = useState(0);
	const [itens, setItens] = useState(null);
	const [itensSelecionados, setItensSelecionados] = useState([]);

	const [exibeTelaAuditoria, setExibirTelaAuditoria] = useState(false);
	const [telaAuditoriaLoading, setTelaAuditoriaLoading] = useState(false);
	const [telaAuditoriaId, setTelaAuditoriaId] = useState(false);
	const [dadosAuditoria, setDadosAuditoria] = useState(false);
	const [telaMobile, setTelaMobile] = useState(window.innerWidth <= 575);

	const [filtro, setFiltro] = useState(null);
	const [valorGlobalFiltro, setValorGlobalFiltro] = useState('');

	const [loadingDados, setLoadingDados] = useState(true);

	window.addEventListener('resize', () => {
		setTelaMobile(window.innerWidth <= 575);
	});

	const aoMudarFiltroGlobal = (e) => {
		const value = e.target.value;
		let _filtro = {...filtro};
		_filtro['global'].value = value;

		setFiltro(_filtro);
		setValorGlobalFiltro(value);
	}

	useEffect(() => {
		iniciarFiltro();
	}, []);


	const iniciarFiltro = () => {
		setFiltro({
			'global':         {value: null, matchMode: FilterMatchMode.CONTAINS},
			'name':           {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
			'country.name':   {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
			'representative': {value: null, matchMode: FilterMatchMode.IN},
			'date':           {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
			'balance':        {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			'status':         {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			'activity':       {value: null, matchMode: FilterMatchMode.BETWEEN},
			'verified':       {value: null, matchMode: FilterMatchMode.EQUALS}
		});
		setValorGlobalFiltro('');
	}

	const cabecalhoTabela = (<>
		{parametros.pesquisarTabela &&
			<CabecalhoTabela>
				<h5 className="m-0">{textos.plural(`${parametros.nomeClasse} já cadastrad${(parametros.artigo ?? 'o')}`)}</h5>
				<span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={valorGlobalFiltro} onChange={aoMudarFiltroGlobal} placeholder="Pesquise aqui"/>
                </span>
			</CabecalhoTabela>
		}
	</>);

	const verificaTolerancia = (cadastro) => {
		const dateCadastro = new Date(cadastro);
		const now = new Date()
		const diffMs = now - dateCadastro;
		const diffMinutes = diffMs / (1000 * 60);
		return diffMinutes <= 5;
	}

	const abrirAuditoria = (registro) => {
		setTelaAuditoriaLoading(true);
		setDadosAuditoria('');

		if (registro.id) {
			setTelaAuditoriaId(registro.id);
			setExibirTelaAuditoria(true);
		}
		requisitarAuditoria(registro.id);
	}

	const requisitarAuditoria = (id) => {
		new api().requisitar('POST', 'seguranca/auditoria', {
			classe:     parametros.tabela,
			idregistro: id
		}).then((resposta) => {
			if (resposta) {
				setDadosAuditoria(resposta);
			}
			setTelaAuditoriaLoading(false);
		})
	}

	const auditoriaItemN = (item) => {
		if (item.campo === 'imagem') {
			return (<img src={item.novoValor} height={50}/>);
		}
		if (item.campo === 'imagem_mobile') {
			return (<img src={item.novoValor} height={50}/>);
		}
		if (item.campo === 'icone') {
			return (<i className={item.novoValor}></i>);
		}

		return item.novoValor
	}

	const auditoriaItemA = (item) => {
		if (item.campo === 'imagem') {
			return (<img src={item.valorAntigo} height={50}/>);
		}
		if (item.campo === 'imagem_mobile') {
			return (<img src={item.valorAntigo} height={50}/>);
		}
		if (item.campo === 'icone') {
			return (<i className={item.valorAntigo}></i>);
		}

		return item.valorAntigo
	}

	const auditoriaItemTemplate = (product, layout) => {
		if (!product) {
			return;
		}

		let detalhes = '';

		if (product.detalhes.length > 0) {
			detalhes = (
				<DataTable value={product.detalhes} responsiveLayout="stack" breakpoint="960px">
					<Column field="campo" header="Campo"></Column>
					<Column field="valorAntigo" header="Valor antigo" body={auditoriaItemA}></Column>
					<Column field="novoValor" header="Valor novo" body={auditoriaItemN}></Column>
				</DataTable>
			);
		}

		return (
			<div className="col-12">
				<div className="product-list-item">
					<div className="product-list-detail">
						<div className="product-name">
							<h3>{product.acao} por {product.usuario} ({product.ip}) em {product.data}</h3>
						</div>
						{detalhes ?? ''}
					</div>
				</div>
			</div>
		);
		;
	}

	const botoesEsquerdaTabela = () => {
		if (telaMobile) {
			return (<>
				{parametros.tabelaDados.botaoAdicionar &&
					<Button icon="pi pi-plus" className="p-button-success p-button-rounded mr-2 mt-2" onClick={parametros.abrirFormulario}/>
				}
				{parametros.tabelaDados.botaoExcluir && parametros.tabelaDados.multiplaSelecao &&
					<Button icon="pi pi-trash" className="p-button-danger p-button-rounded mr-2 mt-2" disabled={!itensSelecionados || !itensSelecionados.length} onClick={aoExcluir}/>
				}
				{parametros.tabelaDados.botaoCustomizavel ? parametros.tabelaDados.botaoCustomizavel : null}
			</>);
		} else {
			return (<>
				{parametros.tabelaDados.botaoAdicionar &&
					<Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2 mt-2" onClick={parametros.abrirFormulario}/>
				}
				{parametros.tabelaDados.botaoExcluir && parametros.tabelaDados.multiplaSelecao && !parametros.desativar &&
					<Button label="Excluir" icon="pi pi-trash" className="p-button-danger mr-2 mt-2" disabled={!itensSelecionados || !itensSelecionados.length} onClick={aoExcluir}/>
				}
				{parametros.tabelaDados.botaoExcluir && parametros.tabelaDados.multiplaSelecao && parametros.desativar &&
					<Button label="Desativar" icon="pi pi-trash" className="p-button-danger mr-2 mt-2" disabled={!itensSelecionados || !itensSelecionados.length} onClick={aoExcluir}/>
				}
				{parametros.tabelaDados.botaoCustomizavel ? parametros.tabelaDados.botaoCustomizavel : null}
			</>);
		}
	};

	const botoesDireitaTabela = () => {
		return (<React.Fragment>
			<Button label="Atualizar" icon="pi pi-refresh" className="p-button-info mt-2" onClick={carregarItens}/>
		</React.Fragment>);
	};

	const botoesRegistro = (registro) => {
		let toleranciaAoCadastrar = false;
		if(parametros.tabelaDados.toleranciaEdicao && registro['datacadastro']) {
			toleranciaAoCadastrar = verificaTolerancia(registro['datacadastro']);
		}
		return (<React.Fragment>
			{parametros.tabelaDados.botaoVisualizarRegistro &&
				<Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => (parametros.abrirVisualizacao !== undefined) ? parametros.abrirVisualizacao(registro) : parametros.abrirFormulario(registro)}/>
			}
			{(parametros.tabelaDados.botaoEditarRegistro || toleranciaAoCadastrar)&&
				<Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => parametros.abrirFormulario(registro)}/>
			}
			{(parametros.tabelaDados.botaoExcluirRegistro || toleranciaAoCadastrar) &&
				<Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => aoExcluir(registro)}/>
			}
			{parametros.tabelaDados.botaoAuditoriaRegistro &&
				<Button icon="pi pi-exclamation-circle" className="p-button-rounded p-button-warning mr-2" onClick={() => abrirAuditoria(registro)}/>
			}
		</React.Fragment>);
	};

	const totalizador = () => {
		return (<>
			{qtdeItens > 0 &&
				<Card>
					<div className="a">{textos.plural(`${parametros.nomeClasse} cadastrado`, qtdeItens > 1)} <strong>{new Intl.NumberFormat('pt-BR').format(qtdeItens)}</strong></div>
				</Card>
			}
		</>);
	}

	// metodos
	const carregarItens = async () => {
		if(parametros.tabelaDados.apresentarDados) {
			if(itens === null){
				setLoadingDados(true);
			}
			await new api().requisitar('get', parametros.endpointApi).then((resposta) => {
				setItens(resposta);
				setQtdeItens(resposta?.length ?? 0);
				parametros.finalizarPesquisa();
				setLoadingDados(false);
			}).catch((erro) => {
				console.log(erro);
			});
		}
	};

	const excluir = async (id) => {
		let ids = id ? id : itensSelecionados.map((i) => {
			return i.id
		}).join(',');

		await new api().requisitar('DELETE', `${parametros.endpointApi}/${ids}`).then((resposta) => {
			aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
			carregarItens();
		}).catch((erro) => {
			carregarItens();
			const msgErro = (erro.response.data.error_description ?? erro.response.data) ?? 'Ocorreu um erro não tratado';
			aviso.current.show({
				severity: 'error',
				summary:  'Erro',
				detail:   msgErro,
				life:     3000
			});
		});
	};

	const aoExcluir = (registro) => {
		const txtExcluir = parametros.desativar === true ? 'desativar' : 'excluir';
		confirmDialog({
			message:         `Deseja realmente ${txtExcluir} ${textos.plural('o registro selecionado', (itensSelecionados.length > 1))}?`,
			header:          'Atenção',
			icon:            'pi pi-exclamation-triangle',
			acceptLabel:     'Sim',
			acceptClassName: 'p-button-btxpp',
			rejectLabel:     'Não',
			rejectClassName: 'p-button-btxpp p-button-outlined',
			accept:          () => {
				excluir(registro.id ?? null);
			}
		});
	};

	const aoReordenar = (e) => {
		setItens(e.value);
		const ordenacao = [];
		for (let i = 0; i < e.value.length; i++) {
			ordenacao.push({id: e.value[i].id, ordem: i});
		}

		if (ordenacao.length > 0) {
			new api().requisitar('PUT', `${parametros.endpointApi}/ordenar`, {ordem: ordenacao}).then((resposta) => {
				aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
				carregarItens();
			}).catch((erro) => {
				const msgErro = (erro.response.data.error_description ?? erro.response.data) ?? 'Ocorreu um erro não tratado';
				aviso.current.show({
					severity: 'error',
					summary:  'Erro',
					detail:   msgErro,
					life:     3000
				});
			});
		}
	}

	useEffect(() => {
		if (parametros.endpointApi) {
			carregarItens();
		}
	}, [parametros]);

	useEffect(() => {
		if (listar) {
			if (parametros.endpointApi) {
				carregarItens();
			}
		}
	}, [listar]);

	return (
		<Container>
			{parametros.tabelaDados.apresentarDados ? (
				<>
					<Toast ref={aviso} />
					<BreadCrumb model={parametros.caminhoBreadcrumb} home={iconeHome} />
					{!parametros.telaCustomizada && (
						<Tela>
							{loadingDados ? (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
									<ProgressSpinner
										style={{ width: '50px', height: '50px' }}
										strokeWidth="8"
										fill="var(--surface-ground)"
										animationDuration=".5s"
									/>
								</div>
							) : (
								<>
									<Toolbar left={botoesEsquerdaTabela} right={botoesDireitaTabela} />
									{parametros.tabelaDados && (
										<DataTable
											paginator
											className="datatable-sm"
											removableSort
											dataKey="id"
											responsiveLayout="stack"
											breakpoint="575px"
											rows={10}
											rowsPerPageOptions={[5, 10, 25, 100, 1000]}
											emptyMessage=" "
											ref={grid}
											header={cabecalhoTabela}
											value={itens}
											selection={itensSelecionados}
											globalFilter={pesquisaGeral}
											filters={filtro}
											reorderableRows={parametros.tabelaDados.reordenar === true}
											onRowReorder={aoReordenar}
											onSelectionChange={(e) => setItensSelecionados(e.value)}
										>
											{parametros.tabelaDados.reordenar === true && (
												<Column rowReorder style={{ width: '30px' }} />
											)}
											{parametros.tabelaDados.multiplaSelecao && (
												<Column
													selectionMode="multiple"
													headerStyle={{ width: '50px' }}
													style={{ width: '50px' }}
												/>
											)}
											{parametros.tabelaDados.colunas.map((coluna) => {
												if(!parametros.tabelaDados.apresentarCampos &&
													parametros.tabelaDados.esconderCampos &&
													parametros.tabelaDados.esconderCampos.includes(coluna.campo)
												) {
													return (
														<Column
															key={coluna.campo}
															field={coluna.campo}
															header={coluna.legenda}
															body={(rowdata) => {
																if(rowdata['datacadastro'] && verificaTolerancia(rowdata['datacadastro'])) {
																	return (
																		<span>{rowdata[coluna.campo]}</span>
																	);
																}
																return (
																	<span>****</span>
																);
															}}

															sortable={coluna.ordenavel}
														/>
													);
												}

												if (coluna.rel) {
													return (
														<Column
															key={coluna.campo}
															field={coluna.campo}
															header={coluna.legenda}
															body={(rowdata) => (
																<span>{coluna.rel[rowdata[coluna.campo]]}</span>
															)}
															sortable={coluna.ordenavel}
														/>
													);
												}

												if (coluna.template) {
													return (
														<Column
															key={coluna.campo}
															field={coluna.campo}
															header={coluna.legenda}
															sortable={coluna.ordenavel}
															body={coluna.template}
														/>
													);
												}

												return (
													<Column
														key={coluna.campo}
														field={coluna.campo}
														header={coluna.legenda}
														sortable={coluna.ordenavel}
													/>
												);
											})}
											{(parametros.tabelaDados.botaoEditarRegistro ||
												parametros.tabelaDados.botaoExcluir ||
												parametros.tabelaDados.botaoAuditoriaRegistro ||
												parametros.tabelaDados.botaoVisualizarRegistro ||
												parametros.tabelaDados.toleranciaEdicao ) && (
												<Column
													body={botoesRegistro}
													style={{ minWidth: 180, textAlign: 'right', justifyContent: 'right' }}
												/>
											)}
										</DataTable>
									)}
									{children}
									{totalizador}
								</>
							)}
						</Tela>
					)}
				</>
			) : (
				<></>
			)}
			{parametros.telaCustomizada && <div>{parametros.telaCustomizada}</div>}
			<Dialog
				className="fluid"
				header={`Auditoria ${parametros.nomeClasse}`}
				style={{ maxWidth: '99%' }}
				modal
				visible={exibeTelaAuditoria}
				onHide={() => setExibirTelaAuditoria(false)}
			>
				<div className="grid formgrid">
					<div className="dataview-demo">
						<div className="card">
							{telaAuditoriaLoading ? (
								<div className="col-12" style={{ display: 'flex' }}>
									<i className="pi pi-spin pi-spinner m-auto"></i>
								</div>
							) : (
								<DataView
									value={dadosAuditoria}
									layout="list"
									emptyMessage="Nenhum dado encontrado.."
									itemTemplate={auditoriaItemTemplate}
									paginator
									rows={9}
								/>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</Container>
	);

}

export default Tabela;