import styled, { createGlobalStyle  } from 'styled-components';

import "font-awesome/css/font-awesome.css";

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const Tela = styled.div`
	background: var(--surface-e);
	-webkit-box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
	border-radius: 4px;
	margin-bottom: 2rem;
	width: 100%;
	min-width: 300px;
`;

export const CabecalhoTabela = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Obrigatorio = styled.span`
	color: #ff0000;
	padding-left: 5px;
`;

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		outline: 0;
	}

	body, html {
		background: #eeeeee;
		font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		height: 100%;
		width: 100%;
	}

	.botao, 
	.botao:enabled, 
	.botao:active, 
	.p-button:not(a):not(.p-disabled){
		background-color: #060606;
		color: #ffffff;
		border-color: #060606;
	}

  .p-button:not(a):not(.p-disabled):active,
  .p-button:not(a):not(.p-disabled):hover {
    background-color: #060606;
    color: #ffd700;
    border-color: #060606;
  }

	.campo {
		background-color: #ffffff;
		border-color:#cccc00;
		color: #060606;
	}
	
	.campo:enabled:hover,
	.campo:enabled:active,
	.campo:enabled:focus {
		border-color:#999900;
    box-shadow: 0 0 0 0.2rem #cccc00;
	}
	
	.p-menubar,
  .p-menu-list,
  .p-menubar .p-submenu-list,
	.p-menuitem {
		background-color: #ffd700;
		color: #060606;
	}

  .p-menuitem .p-menuitem-active,
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover{
	  color: #060606;;
	  background-color:  #cccc00;
  }

`;

export default GlobalStyle;
