import styled from "styled-components";

export const Container = styled.div`
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 30vw;
`;

export const LateralMenu = styled.div`
	background-color: #FFFFFF;
	height: 100%;
`;

export const Conteudo = styled.div`
	padding: 20px;
	width: 100%;
`;

export const NomeSistema = styled.div`
	padding-left: 20px;
	font-weight: bolder;
	color: #004972;
`;
