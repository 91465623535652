import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dropdown}                           from "primereact/dropdown";
import {Dialog}                             from "primereact/dialog";
import {ListBox}                            from "primereact/listbox";
import {AutoComplete}                       from 'primereact/autocomplete';
import {PrimeIcons}                         from 'primereact/api';

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import debug              from "../../utils/debug";
import useMobile          from "../../utils/useMobile";
import {parametrosPadrao} from "../../configs/constantes";
import {MultiSelect}      from "primereact/multiselect";


const Tela = () => {
	const classe = 'Tela';
	const endpointAPI = 'configuracao/tela';
	const tabela = 'telas';
	const inicial = {
		id:              0,
		nome:            '',
		titulo:          '',
		rotaportal:      '',
		idmodulo:        0,
		icone:           '',
		funcionalidades: []
	};
	const aviso = useRef(null);
	const [item, setItem] = useState(inicial);
	const [tituloTela, setTituloTela] = useState('');
	const [exibeTela, setExibirTela] = useState(false);
	const [listar, setListar] = useState(false);
	const [listaModulo, setListaModulo] = useState(null);
	const [listaIcones, setListaIcones] = useState(null);
	const [funcionalidades, setFuncionalidades] = useState([]);
	const [funcionalidadesFiltradas, setFuncionalidadesFiltradas] = useState(null);
	const {isMobile} = useMobile();
	const [permissoesTela, setPermissoesTela] = useState(null);
	const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
	
	useEffect((e) => {
		new api().requisitar('get', `configuracao/tela/tela/permissoes`).then((resposta) => {
			setPermissoesTela(resposta);
			const parametros = {
				pesquisarTabela:   true,
				artigo:            'a',
				tabela:            tabela,
				nomeClasse:        classe,
				caminhoBreadcrumb: [{label: 'Segurança'}, {label: 'Telas'}],
				endpointApi:       endpointAPI,
				tabelaDados:       {
					reordenar:               true,
					multiplaSelecao:         true,
					apresentarDados:         resposta.listar ?? false,
					botaoAdicionar:          resposta.cadastrar ?? false,
					botaoExcluir:            resposta.excluir ?? false,
					botaoEditarRegistro:     resposta.alterar ?? false,
					botaoExcluirRegistro:    resposta.excluir ?? false,
					botaoAuditoriaRegistro:  resposta.auditoria ?? false,
					botaoVisualizarRegistro: resposta.visualizar ?? false,
					colunas:                 [{legenda: 'Título', campo: 'titulo', ordenavel: true}, {legenda: 'Rota', campo: 'rotaportal', ordenavel: true}]
				},
				abrirFormulario:   aoAbrirFormulario,
				finalizarPesquisa: aoListar
			}
			setParametrosTela(parametros);
		}).catch((erro) => {
			throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
		});
		
		new api().requisitar('get', `/configuracao/funcionalidade/combo`).then((resposta) => {
			setFuncionalidades(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});
		
		new api().requisitar('get', `/configuracao/modulo/combo`).then((resposta) => {
			setListaModulo(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});
		
		let temp = [];
		for (let icone in PrimeIcons) {
			temp.push({id: PrimeIcons[icone], valor: PrimeIcons[icone],})
		}
		setListaIcones(temp);
		
	}, []);
	
	const aoAbrirFormulario = (registro) => {
		if (registro.id) {
			new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
				setItem(resposta);
				setTituloTela(`Alterar ${classe}`);
			})
		} else {
			setItem(inicial);
			setTituloTela(`Cadastro de ${classe}`);
		}
		setExibirTela(true);
	};
	
	const aoListar = () => setListar(false);
	
	const filtrarFuncionalidades = (e) => {
		setTimeout(() => {
			let _funcionalidades;
			if (!e.query.trim().length) {
				_funcionalidades = [...funcionalidades];
			} else {
				_funcionalidades = funcionalidades.filter((f) => {
					return f.valor.toString().toUpperCase().startsWith(e.query.toUpperCase());
				});
			}
			
			setFuncionalidadesFiltradas(_funcionalidades);
		}, 250);
	};
	
	const limparTela = () => {
		setItem(inicial);
		setExibirTela(false);
		setListar(true);
	};
	
	const excluir = async (id) => {
		await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
			aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
			limparTela();
		}).catch((erro) => {
			debug(erro);
			aviso.current.show({
				                   severity: 'error',
				                   summary:  'Erro',
				                   detail:   (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
				                   life:     3000
			                   });
		});
	};
	
	const aoExcluirItem = (id) => {
		confirmDialog({
			              message:     `Confirma exclusão da ${classe}?`,
			              header:      'Atenção',
			              icon:        'pi pi-exclamation-triangle',
			              acceptLabel: 'Sim',
			              rejectLabel: 'Não',
			              accept:      () => {
				              excluir(id);
			              },
		              });
	};
	
	const aoSalvar = async () => {
		try {
			if (!item.idmodulo) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o módulo!'};
			}
			
			if (!item.rotaportal) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a rota para acesso à tela!'};
			}
			
			if (!item.titulo) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título!'};
			}
			
			if (!item.nome) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
			}
			
			if (!item.funcionalidades || item.funcionalidades.length < 1) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe pelo menos uma funcionalidade!'};
			}
			
			await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
				aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
				limparTela();
			}).catch((erro) => {
				throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
			});
			
		} catch (erro) {
			aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
		}
	};
	
	const aoDigitar = (e, propriedade) => {
		const texto = (e.target && e.target.value) || '';
		let _item = {...item};
		_item[`${propriedade}`] = texto;
		setItem(_item);
	};
	
	const aoEnter = (e) => {
		if (e.key === 'Enter') {
			aoSalvar(e);
		}
	};
	
	const iconeTpl = (icone) => <div className="d-flex flex-row"><i className={`${icone.valor} mr-2`}></i>{icone.valor}</div>;
	
	const iconeSelTpl = (icone, props) => {
		if (icone) {
			return (<div><i className={`${icone.valor} mr-2`}></i>{icone.valor}</div>);
		}
		
		return (<div>&nbsp;</div>);
	}
	
	const botoesAcaoTela = () => {
		return (<React.Fragment>
			<Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
			{item.id > 0 && permissoesTela.excluir && <Button label="Excluir" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
			{((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
		</React.Fragment>);
	};
	
	return (
		<Tabela parametros={parametrosTela} listar={listar}>
			<Toast ref={aviso}></Toast>
			<Dialog className='fluid' header={tituloTela} style={{maxWidth: isMobile ? '450px' : '650px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
				<div className="grid formgrid">
					<div className="field col-12 mt-3">
						<label htmlFor="idmodulo">Módulo<Obrigatorio>*</Obrigatorio> </label><br/>
						<Dropdown className="w-full" autoComplete="none" id="idmodulo" value={item.idmodulo} options={listaModulo} onChange={(e) => aoDigitar(e, 'idmodulo')} optionLabel="valor"
						          optionValue="id" required/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="rotaportal">Rota Portal<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" placeholder={'/modulo/tela'} autoComplete="none" id="rotaportal" maxLength={100} value={item.rotaportal} onChange={(e) => aoDigitar(e, 'rotaportal')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" autoComplete="none" id="titulo" maxLength={50} value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12 sm:col-6 mt-3">
						<label htmlFor="nome">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12 sm:col-6 mt-3">
						<label htmlFor="icone">Ícone</label>
						<Dropdown className="w-full" autoComplete="none" showClear id="icone" value={item.icone} options={listaIcones} onChange={(e) => aoDigitar(e, 'icone')} optionLabel="valor"
						          optionValue="id" filter itemTemplate={iconeTpl} valueTemplate={iconeSelTpl} required/>
					</div>
					
					<div className="field col-12 mt-3">
						<span className="p-fluid">
							<label htmlFor="funcionalidades">Funcionalidades<Obrigatorio>*</Obrigatorio> </label><br/>
							<MultiSelect className="w-full" value={item.funcionalidades} options={funcionalidades} onChange={(e) => aoDigitar(e, 'funcionalidades')} optionLabel="valor" placeholder="Funcionalidades..." display="chip" />
                        </span>
					</div>
				
				</div>
			</Dialog>
		</Tabela>
	);
}


export default Tela;
