import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import { ProgressSpinner }                  from 'primereact/progressspinner';
import {InputNumber}                        from "primereact/inputnumber";
import {Calendar}                           from  "primereact/calendar";
import {Dropdown}                           from "primereact/dropdown";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";
import {InputText} from "primereact/inputtext";
import {Tooltip} from "primereact/tooltip";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const VendaInterna = () => {
    const classe = 'Vendas Internas';
    const tabela = 'vendas_internas';
    const endpointAPI = 'vendas/venda-interna';
    const endpointRelatorioAPI = 'relatorios/relatorio';
    const aviso = useRef(null);
    const inicial = {id: 0, idloja: null, idusuario: null,  qtdpecas:null, valor: null, data: null};
    const [item, setItem] = useState(inicial);

    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [lojaLista, setLojaLista] = useState([]);
    const [funcionarioLista, setFuncionarioLista] = useState([]);

    const [loadingFormulario, setLoadingFormulario] = useState(true);

    const relatorioInicial = {idusuario: null, periodo: null}
    const [relatorio, setRelatorio] = useState(relatorioInicial);

    const [exibirGeracaoRelatorio, setExibirGeracaoRelatorio] = useState(false);
    const [exibirRelatorioVenda, setExibirRelatorioVenda] = useState(false);

    const relatorioVendaParamentrosInicial = {colunas: [], dados: [], funcionario: {}, totais: {}};
    const [relatorioVendaParamentros, setRelatorioVendaParamentros] = useState(relatorioVendaParamentrosInicial);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/vendas-internas/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                artigo:            'a',
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Vendas'}, {label: 'Vendas Internas'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : resposta.listar ?? false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    botaoCustomizavel      : resposta.relatorio ?
                        <>
                            <Button label="Gerar Relatório de Vendas Internas" icon="pi pi-plus" className="p-button-info mt-2 mr-2" onClick={() => aoAbirGeracaoRelatorio()}/>
                        </>
                        : null,
                    filtroStatus           : false,
                    colunas                : [
                        {legenda: 'Data', campo: 'data', ordenavel: true},
                        {legenda: 'Loja', campo: 'loja_nome', ordenavel: true},
                        {legenda: 'Funcionário', campo: 'usuario_nome', ordenavel: true},
                        {legenda: 'Quantidade de Peças', campo: 'qtdpecas', ordenavel: true},
                        {legenda: 'Valor', campo: 'valor', ordenavel: true},
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'cadastro/loja/combo').then((resposta) => {
            setLojaLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'seguranca/usuario/combo-funcionarios').then((resposta) => {
            setFuncionarioLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

    }, []);

    const formatarData = (data) => {
        const [parteData, parteHora] = data.split(' ');
        const [ano, mes, dia] = parteData.split('-');
        const [horas, minutos, segundos] = parteHora.split(':');
        return new Date(ano, mes - 1, dia, horas, minutos, segundos);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setRelatorio(relatorioInicial);
        setExibirTela(false);
        setExibirGeracaoRelatorio(false);
        setExibirRelatorioVenda(false);
        setRelatorioVendaParamentros(relatorioVendaParamentrosInicial);
        setListar(true);
    };

    const aoAbirGeracaoRelatorio = () => {
        setExibirGeracaoRelatorio(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            limparTela();
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão do ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const verificaUsuarioLoja = async () => {
        try {
            const loja = await new api().requisitar('get', 'cadastro/loja/usuario-loja');
            let _item = item;
            if (loja['id'] !== false) {
                setLojaLista([loja]);
                _item.idloja = loja['id'];
                setItem(_item);
            }
        } catch (erro) {
            debug(erro);
        }
    }

    const aoAbrirFormulario = async (registro) => {
        setItem(inicial);
        setExibirTela(true);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then(async (resposta) => {
                resposta.data = formatarData(resposta.data);
                setItem(resposta);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            await verificaUsuarioLoja();
            setLoadingFormulario(false);
        }
    };

    const aoSalvar = async () => {
        try {
            if (!item.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if (!item.idusuario) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe uma descrição!'};
            }
            if (!item.qtdpecas) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o tipo da transação!'};
            }
            if (!item.valor) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o valor da transação!'};
            }
            if (!item.data) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a data da transação!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                limparTela();
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade, elemento) => {
        if(elemento === 'item') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            setItem(_item);
        } else if(elemento === 'relatorio') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _relatorio = {...relatorio};
            _relatorio[`${propriedade}`] = texto;
            setRelatorio(_relatorio);
        }
    };

    const estruturarRelatorio = (dados, totais, funcionario) => {
        const vendasFormatada = [];
        dados.forEach((venda) => {
            const vendaFormatada = {
                data: venda.data,
                qtdpecas: venda.qtdpecas,
                valor: (venda.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            };
            vendasFormatada.push(vendaFormatada);
        });

        const parametrosRelatorio=  {
            "colunas": [
                { "field": "data", "header": "Data"},
                { "field": "qtdpecas", "header": "Quantidade de Peças"},
                { "field": "valor", "header": "Valor"},
            ],
            "dados": vendasFormatada,
            "funcionario": {
                "id": funcionario.id,
                "nome": funcionario.nome
            },
            "totais": {
                "qtdPecas": totais.qtdPecas,
                "valor": (totais.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            }
        }
        setRelatorioVendaParamentros(parametrosRelatorio);
    }

    const aoGerarRelatorio = async () => {
        try {
            if (!relatorio.idusuario) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o funcionário!'};
            }

            if (!relatorio.periodo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o período!'};
            }

            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/vendas-internas`, relatorio)
                .then((resposta) => {
                    // Exibir mensagem, caso tenha vendas gera relatório
                    setExibirGeracaoRelatorio(false);
                    if(resposta.dados && resposta.dados.length > 0){
                        setExibirRelatorioVenda(true);
                        estruturarRelatorio(resposta.dados, resposta.totais, resposta.funcionario);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 });
                    } else {
                        limparTela();
                        aviso.current.show({severity: 'warn', summary: 'Nenhuma Venda Encontrada', detail: 'Nenhuma venda encontrada para os filtros aplicados', life: 4000});
                    }
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const exportarPdf = (tipo) => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 'mm', 'a4');

                // Adiciona o título
                doc.setFontSize(18);
                const titulo = tipo === 'venda' ? `Vendas Internas - ${relatorioVendaParamentros.funcionario.nome}` : '';
                doc.text(`Relatório de ${titulo}`, 14,20);

                // Define header e body
                const colunas = tipo === 'venda' ?
                    relatorioVendaParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }))
                    : null;
                const data = tipo === 'venda' ? relatorioVendaParamentros.dados : null;

                const footerRow = tipo === 'venda' ?  [
                    {
                        "data": "Totais: ",
                        "qtdpecas": relatorioVendaParamentros.totais.qtdPecas,
                        "valor": relatorioVendaParamentros.totais.valor
                    }
                ] : [];

                const body = [...data, ...footerRow];

                doc.autoTable(colunas, body, {
                    margin: {top: 25},
                });

                const nomeRelatorio = tipo === 'venda' ? `Relatório de Vendas Internas  - ${relatorioVendaParamentros.funcionario.nome}.pdf` : ``;
                doc.save(nomeRelatorio);
            });
        });
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const botoesAcaoTelaRelatorio = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorio}/>
        </React.Fragment>);
    };

    const botoesRelatorio = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportarPdf('venda')} data-pr-tooltip="PDF" />
        </div>
    );

    const totais = (
        <ColumnGroup>
            <Row>
                <Column footer="Totais:" footerStyle={{ textAlign: 'right' }} />
                <Column footer={relatorioVendaParamentros.totais.qtdPecas} />
                <Column footer={relatorioVendaParamentros.totais.valor} />
            </Row>
        </ColumnGroup>
    );

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idloja">Loja<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Dropdown className="w-full" autoComplete="none" id="idloja" value={item.idloja} options={lojaLista} onChange={(e) => aoDigitar(e, 'idloja', 'item')} optionLabel="valor"
                                              optionValue="id" required/>
                                </div>
                            </div>

                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idusuario">Funcionário<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Dropdown className="w-full" autoComplete="none" id="idusuario" value={item.idusuario} options={funcionarioLista} onChange={(e) => aoDigitar(e, 'idusuario', 'item')} optionLabel="valor"
                                              optionValue="id" required/>
                                </div>
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="flex col-12 p-0 mt-3">
                                <div className="field col-6">
                                    <label htmlFor="idpais">Data<Obrigatorio>*</Obrigatorio> </label><br/>
                                    <Calendar
                                        id="time24"
                                        value={item.data}
                                        onChange={(e) => aoDigitar(e, 'data', 'item')}
                                        className="w-full"
                                        dateFormat="dd/mm/yy"
                                        hourFormat="24"
                                        showTime
                                    />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="valor">Quantidade de Peças<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputNumber
                                        inputId="qtdpecas"
                                        value={item.qtdpecas}
                                        onChange={(e) => aoDigitar(e, 'qtdpecas', 'item')}
                                    />
                                </div>
                                <div className="field col-3">
                                    <label htmlFor="valor">Valor<Obrigatorio>*</Obrigatorio></label><br />
                                    <InputNumber
                                        inputId="valor"
                                        value={item.valor}
                                        onChange={(e) => aoDigitar(e, 'valor', 'item')}
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Dialog>

            <Dialog className='fluid' header='Geração de Relatório' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorio} modal={true} visible={exibirGeracaoRelatorio} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idusuario">Funcionário<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idusuario" value={relatorio.idusuario} options={funcionarioLista} onChange={(e) => aoDigitar(e, 'idusuario', 'relatorio')} optionLabel="valor"
                                  optionValue="id" required />
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Período<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Calendar
                            value={relatorio.periodo}
                            onChange={(e) => aoDigitar(e, 'periodo', 'relatorio')}
                            view="month"
                            dateFormat="mm/yy"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                className='fluid'
                header={`Relatório de Vendas Internas - ${relatorioVendaParamentros.funcionario.nome}`}
                style={{ maxWidth: '95%', width: '95%' }}
                contentStyle={{ height: '100%', width: '100%' }}
                modal={true}
                visible={exibirRelatorioVenda}
                onHide={limparTela}
                closeOnEscape={false}
                maximizable
            >
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={relatorioVendaParamentros.dados} header={botoesRelatorio} tableStyle={{ width: '100%' }} footerColumnGroup={totais} showGridlines scrollable scrollHeight="flex">
                    {relatorioVendaParamentros.colunas.map((col, index) => {
                        return (
                            <Column
                                key={index}
                                field={col.field}
                                header={col.header}
                            />
                        );
                    })}
                </DataTable>
            </Dialog>
        </Tabela>
    );
}

export default VendaInterna;
