import styled from "styled-components";

export const gridBotao = styled.div`
   text-align: center;
`;

export const ButtonAcessar = styled.div`
    .botao{
        width: 100%;
        background-color: var(--primary) !important;
        color: white !important;
        border: none !important;
        border-radius: 0% !important;
        font-size: 16px !important;
    }
`;

export const ButtonLimpar = styled.div`
    .botao{ 
        width: 100%; 
        background-color: var(--secondary) !important; 
        color: white !important;
        border: none !important;
        border-radius: 0% !important;
        font-size: 16px !important;
    }
`;

export const Logo = styled.img`
    width: 100%;
`;

export const Formulario = styled.div`
    .campo{
        width: 100%;
    }
`;

export const ButtonVoltar = styled.div`
    .botao{ 
        width: 100%; 
        background-color: var(--secondary) !important; 
        color: white !important;
        border: none !important;
        border-radius: 0% !important;
        font-size: 16px !important;
    }
`;

export const ButtonEnviar = styled.div`
    .botao{
        width: 100%;
        background-color: var(--primary) !important;
        color: white !important;
        border: none !important;
        border-radius: 0% !important;
        font-size: 16px !important;
    }
`;