import React        from 'react';
import {classNames} from 'primereact/utils';

import Logo from "../assets/logo.png";
import { useNavigate } from 'react-router-dom';

const AppTopbar = (props) => {
	const menuButtonClassName = classNames('p-link', {'menu-button-rotate': props.rotateMenuButton});
	const legendaTitulo = 'Malukao da Moda';

	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/sistema');
	}

	return (
		<div className="topbar clearfix">
			<div className="topbar-left" style={{display: 'flex', justifyContent: 'center'}}>
				<button className="p-link" onClick={handleClick}>
					<img src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"} style={{width: '25%'}}/>
				</button>
			</div>
			
			<div className="topbar-right">
				<button className={menuButtonClassName} id="menu-button" onClick={props.onMenuButtonClick}>
					<i className="pi pi-angle-left"></i>
				</button>
			</div>
		</div>
	);
};

export default AppTopbar;
