import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";
import { Tooltip }                          from 'primereact/tooltip';
import {Calendar}                           from "primereact/calendar";
import { DataTable }                        from 'primereact/datatable';
import { Column }                           from 'primereact/column';
import { ColumnGroup }                      from 'primereact/columngroup';
import { Row }                              from 'primereact/row';
import { ProgressSpinner }                  from 'primereact/progressspinner';

import api                from "../../services/api";
import {Obrigatorio, Tela}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import debug              from "../../utils/debug";

const Ponto = () => {
    const classe = 'Ponto';
    const tabela = 'pontos';
    const endpointAPI = 'cadastro/ponto';
    const endpointRelatorioAPI = 'relatorios/relatorio';
    const aviso = useRef(null);
    const inicial = {id: 0, idusuario: null, data_entrada: null, data_saida: null};
    const relatorioInicial = {idloja: null, idusuario: null, periodo: null}
    const [item, setItem] = useState(inicial);
    const [relatorio, setRelatorio] = useState(relatorioInicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [exibirGeracaoRelatorio, setExibirGeracaoRelatorio] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
    const [usuariosLista, setUsuariosLista] = useState([]);
    const [lojasLista, setLojasLista] = useState([]);
    const [usuariosPorLojaLista, setUsuariosPorLojaLista] = useState([]);
    const [exibirRelatorioPonto, setExibirRelatorioPonto] = useState(false);
    const relatorioPontoParamentrosInicial = {colunas: [], dados: [], totais: {}, funcionario: ""};
    const [relatorioPontoParamentros, setRelatorioPontoParamentros] = useState(relatorioPontoParamentrosInicial);
    const [aguardandoAtualizacao, setAguardandoAtualizacao] = useState(false);
    const [desabilitarColaborador, setDesabilitarColaborador] = useState(true);
    const [loadingFormulario, setLoadingFormulario] = useState(true);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/ponto/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Pontos'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    apresentarDados        : resposta.listar ?? false,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.excluir ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    botaoCustomizavel      : resposta.relatorio ? <Button label="Gerar Relatório" icon="pi pi-plus" className="p-button-info mt-2" onClick={() => aoAbirGeracaoRelatorio()}/> : null,
                    filtroStatus           : false,
                    colunas                : [
                        {legenda: 'Colaborador', campo: 'usuario_nome', ordenavel: true},
                        {legenda: 'Data Entrada', campo: 'data_entrada', ordenavel: true},
                        {legenda: 'Data Saída', campo: 'data_saida', ordenavel: true},
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'seguranca/usuario/combo-funcionarios').then((resposta) => {
            setUsuariosLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });

        new api().requisitar('get', 'cadastro/loja/combo').then((resposta) => {
            setLojasLista(resposta);
        }).catch((erro) => {
            debug(erro);
        });
    }, []);

    const carregarUsuariosPorLoja = (loja) => {
        setDesabilitarColaborador(true);
        new api().requisitar('get', `seguranca/usuario/combo-loja/${loja}`).then((resposta) => {
            setUsuariosPorLojaLista(resposta);
            setDesabilitarColaborador(false);
        }).catch((erro) => {
            debug(erro);
        });
    }

    const formatarData = (data) => {
        const [datePart, timePart] = data.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes, seconds] = timePart.split(':');
        return new Date(year, month - 1, day, hours, minutes, seconds);
    };

    const aoAbrirFormulario = (registro) => {
        setItem(inicial);
        setLoadingFormulario(true);
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                resposta.data_entrada = formatarData(resposta.data_entrada);
                resposta.data_saida = resposta.data_saida ? formatarData(resposta.data_saida) : resposta.data_saida;
                setItem(resposta);
                setLoadingFormulario(false);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setTituloTela(`Cadastro de ${classe}`);
            setLoadingFormulario(false);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setRelatorio(relatorioInicial);
        setExibirTela(false);
        setExibirGeracaoRelatorio(false);
        setExibirRelatorioPonto(false);
        setRelatorioPontoParamentros(relatorioPontoParamentrosInicial);
        setUsuariosPorLojaLista([]);
        setDesabilitarColaborador(true);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
            message    : `Confirmar exclusão do ${classe}?`,
            header     : 'Atenção',
            icon       : 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept     : () => {
                excluir(id);
            },
        });
    };

    const aoSalvar = async () => {
        try {
            if (!item.idusuario) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o colaborador!'};
            }
            if (!item.data_entrada) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a data!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade, elemento) => {
        if(elemento === 'item') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            setItem(_item);
        } else if(elemento === 'relatorio') {
            const texto = (e.target && e.target.value) || e.value || '';
            let _relatorio = {...relatorio};
            _relatorio[`${propriedade}`] = texto;
            if(propriedade === 'idloja'){
                _relatorio[`idusuario`] = null;
                carregarUsuariosPorLoja(texto);
            }
            setRelatorio(_relatorio);
        }
    };

    const aoAbirGeracaoRelatorio = () => {
        setExibirGeracaoRelatorio(true);
    };

    const decimalParaHora = (decimal, semVazio = false) => {
        if(decimal === 0 && semVazio){
            return '';
        }
        let horas = Math.floor(decimal);
        let minutos = Math.round((decimal - horas) * 60);
        let horasString = horas.toString().padStart(2, '0');
        let minutosString = minutos.toString().padStart(2, '0');
        return `${horasString}:${minutosString}`;
    }

    const formatarJornada = (jornada) => {
        if(jornada) {
            const jornadaArray = jornada.split(' ');
            return jornadaArray[1]; // Retorna as horas
        }
        return "";
    }

    const formatarDinheiro = (valor, semVazio = false) => {
        if(valor === 0 && semVazio) {
             return '';
        }
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }

    const estruturarRelatorio = (dados, totais, funcionario) => {
        const dadosDiario = [];
        for (var key in dados) {
            if (!dados.hasOwnProperty(key)) continue;

            let val = dados[key];
            let [ano, mes, dia] = key.split('-')
            const dataDados = {
                data: `${dia}/${mes}/${ano}`,
                dia: val.diaSemana,
            };
            if ((val.folga && val.horasTrabalhadas === 0) || (val.feriado && val.horasTrabalhadas === 0)) {
                dataDados.ponto_manha_id = "";
                dataDados.entrada_manha = "";
                dataDados.saida_manha = "";
                dataDados.ponto_noite_id = "";
                dataDados.entrada_noite = "";
                dataDados.saida_noite = "";
                dataDados.horas_trabalhadas = val.folga ? val.folgaMotivo : "Feriado";
                dataDados.horas_atraso = "";
                dataDados.horas_extras = "";
                dataDados.valor_diario_extra = "";
            } else {
                const jornadas = val.jornadas || [];
                const primeiroJornada = jornadas[0] || {};
                const ultimaJornada = jornadas.length > 1 ? jornadas[jornadas.length - 1] : {};

                dataDados.ponto_manha_id = primeiroJornada.idponto || "";
                dataDados.entrada_manha = primeiroJornada.horarioEntrada ? formatarJornada(primeiroJornada.horarioEntrada) : "";
                dataDados.saida_manha = primeiroJornada.horarioSaida ? formatarJornada(primeiroJornada.horarioSaida) : "";
                dataDados.ponto_noite_id = ultimaJornada.idponto || "";
                dataDados.entrada_noite = ultimaJornada.horarioEntrada ? formatarJornada(ultimaJornada.horarioEntrada) : "";
                dataDados.saida_noite = ultimaJornada.horarioSaida ? formatarJornada(ultimaJornada.horarioSaida) : "";
                dataDados.horas_trabalhadas = decimalParaHora(val.horasTrabalhadas, true);
                dataDados.horas_atraso = decimalParaHora(val.atrasoEmHoras, true);
                dataDados.horas_extras = decimalParaHora(val.horasExtras, true);
                dataDados.valor_diario_extra = formatarDinheiro(val.valorDiarioExtra, true);
            }
            dadosDiario.push(dataDados);
        }

        const parametrosRelatorio=  {
            "colunas": [
                { "field": "data", "header": "Data", "canEdit": false },
                { "field": "dia", "header": "Dia Semana", "canEdit": false  },
                { "field": "entrada_manha", "header": "Entrada", "canEdit": true  },
                { "field": "saida_manha", "header": "Saída", "canEdit": true  },
                { "field": "entrada_noite", "header": "Entrada", "canEdit": true  },
                { "field": "saida_noite", "header": "Saída", "canEdit": true  },
                { "field": "horas_trabalhadas", "header": "H. Trabalhadas", "canEdit": false  },
                { "field": "horas_atraso", "header": "H. Atrasos", "canEdit": false  },
                { "field": "horas_extras", "header": "H. Extras", "canEdit": false  },
                { "field": "valor_diario_extra", "header": "Valor Extra", "canEdit": false  }
            ],
            "dados": dadosDiario,
            "totais": {
                "horasTrabalhadas": decimalParaHora(totais.horasTrabalhadas),
                "atrasoEmHoras": decimalParaHora(totais.atrasoEmHoras),
                "horasExtras": decimalParaHora(totais.horasExtras),
                "valorDiarioExtra": (totais.valorDiarioExtra).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                "horasExtraRetirandoAtrasos": decimalParaHora(totais.horasExtras - totais.atrasoEmHoras)
            },
            "funcionario": {
                id: funcionario['id'],
                nome: funcionario['nome']
            }
        }
        setRelatorioPontoParamentros(parametrosRelatorio);
    }

    const aoGerarRelatorio = async (notificacao = true) => {
        try {
            if (!relatorio.idloja) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a loja!'};
            }
            if (!relatorio.idusuario) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o colaborador!'};
            }
            if (!relatorio.periodo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe um periodo válido!'};
            }


            await new api().requisitar('post', `${endpointRelatorioAPI}/gerar-relatorio/ponto`, relatorio)
                .then((resposta) => {
                    // Exibir mensagem de sucesso
                    estruturarRelatorio(resposta.dados, resposta.totais, resposta.funcionario);
                    if(notificacao){
                        setExibirGeracaoRelatorio(false);
                        setExibirRelatorioPonto(true);
                        aviso.current.show({ severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000 })
                    }
                    setAguardandoAtualizacao(false);
                })
                .catch((erro) => {
                    // Lidar com erros
                    throw { tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado' };
                });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
            setAguardandoAtualizacao(false);
        }
    }

    const exportarColunas = relatorioPontoParamentros.colunas.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportarPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 'mm', 'a4');

                // Adiciona o título
                doc.setFontSize(18);
                doc.text(`Relatório de Pontos - ${relatorioPontoParamentros.funcionario.nome}`, 14, 20);

                // Define header e body
                const colunas = exportarColunas;
                const data = relatorioPontoParamentros.dados;

                const footerRow = [
                    {
                        "data": "",
                        "dia": "",
                        "entrada_manha": "",
                        "saida_manha": "",
                        "entrada_noite": "",
                        "saida_noite": "Totais:",
                        "horas_trabalhadas": relatorioPontoParamentros.totais.horasTrabalhadas,
                        "horas_atraso": relatorioPontoParamentros.totais.atrasoEmHoras ,
                        "horas_extras": relatorioPontoParamentros.totais.horasExtras,
                        "valor_diario_extra": relatorioPontoParamentros.totais.valorDiarioExtra
                    },
                    {
                        "data": "",
                        "dia": "",
                        "entrada_manha": "",
                        "saida_manha": "",
                        "entrada_noite": "",
                        "saida_noite": "Total(Extras - Atrasos):",
                        "horas_trabalhadas": relatorioPontoParamentros.totais.horasExtraRetirandoAtrasos ,
                        "horas_atraso": "",
                        "horas_extras": "",
                        "valor_diario_extra": ""
                    }
                ];

                const body = [...data, ...footerRow];

                doc.autoTable(colunas, body, {
                    margin: {top: 25},
                });

                doc.save(`Relatório de Pontos - ${relatorioPontoParamentros.funcionario.nome}.pdf`);
            });
        });
    };

    const editorDeCelula = (options) => {
        if (options.field === 'entrada_manha' || options.field === 'saida_manha' ||
            options.field === 'entrada_noite' || options.field === 'saida_noite') {
            return editorHorario(options);
        }
    };

    const horarioStringParaDate = (horario = null, dia = null, mes = null, ano = null) => {
        const [horas, minutos, segundos] = horario ? horario.split(':').map(Number) : [0, 0, 0];
        const data = new Date();

        // Definir as horas, minutos e segundos
        data.setHours(horas);
        data.setMinutes(minutos);
        data.setSeconds(segundos);

        // Definir o dia, mês e ano se fornecidos
        if (dia !== null) {
            data.setDate(dia);
        }
        if (mes !== null) {
            data.setMonth(mes - 1);
        }
        if (ano !== null) {
            data.setFullYear(ano);
        }

        return data;
    }

    const editorHorario = (options) => {
        let data;
        const horarioString = options.value;
        if(horarioString instanceof Date){
            data = options.value;
        } else {
            if(horarioString === '') {
                data = horarioStringParaDate();
            }
            data = horarioStringParaDate(horarioString);
        }

        return <Calendar
            value={data}
            onChange={(e) => options.editorCallback(e.target.value)}
            disabled={aguardandoAtualizacao}
            timeOnly />
    }

    const aoCompletarEdicao = async (e) => {
        try {
            const { rowData, rowIndex, newValue, field, value } = e;
            if(newValue && (newValue !== value)) {
                let horario;
                if(newValue instanceof Date) {
                    const horas = String(newValue.getHours()).padStart(2, '0');
                    const minutos = String(newValue.getMinutes()).padStart(2, '0');
                    horario = `${horas}:${minutos}:00`;
                } else {
                    horario = newValue;
                }

                const isManha = field.includes('manha');
                const idponto = relatorioPontoParamentros['dados'][rowIndex][isManha ? 'ponto_manha_id' : 'ponto_noite_id'];

                const [dia, mes, ano] = rowData['data'].split('/').map(Number);

                rowData[field] = horario;
                const horarioEntrada = rowData[isManha ? 'entrada_manha' : 'entrada_noite'];
                const horarioSaida = rowData[isManha ? 'saida_manha' : 'saida_noite'];
                const postData = {
                    id: idponto,
                    idusuario: relatorioPontoParamentros['funcionario'].id,
                    data_entrada: horarioEntrada ? horarioStringParaDate(horarioEntrada, dia, mes, ano) : null,
                    data_saida: horarioSaida ? horarioStringParaDate(horarioSaida, dia, mes, ano): null
                };

                // Aguardando atualização
                setAguardandoAtualizacao(true);
                await new api().requisitar((postData.id > 0) ? 'put' : 'post', (postData.id > 0 ? `${endpointAPI}/${postData.id ?? ''}` : endpointAPI), postData).then((resposta) => {
                    aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                    aoGerarRelatorio(false);
                }).catch((erro) => {
                    throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
                })
            }
        } catch (erro) {
            aoGerarRelatorio(false);
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.exluir && <Button label="Excluir" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const botoesAcaoTelaRelatorio = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={limparTela}/>
            {(permissoesTela.cadastrar) && <Button label="Gerar" icon="pi pi-check" className="p-button-text" onClick={aoGerarRelatorio}/>}
        </React.Fragment>);
    };

    const botoesRelatorio = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button label="Salvar em PDF" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportarPdf} data-pr-tooltip="PDF" />
        </div>
    );

    const totais = (
        <ColumnGroup>
            <Row>
                <Column footer="Totais:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
                <Column footer={relatorioPontoParamentros.totais.horasTrabalhadas} />
                <Column footer={relatorioPontoParamentros.totais.atrasoEmHoras} />
                <Column footer={relatorioPontoParamentros.totais.horasExtras} />
                <Column footer={relatorioPontoParamentros.totais.valorDiarioExtra} />
            </Row>
            <Row>
                <Column footer="Total (Extras - Atrasos):" colSpan={6} footerStyle={{ textAlign: 'right' }} />
                <Column footer={relatorioPontoParamentros.totais.horasExtraRetirandoAtrasos} />/>
            </Row>
        </ColumnGroup>
    );

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={limparTela}>
                {loadingFormulario ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '250px' }}>
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                ) : (
                    <>
                        <div className="grid formgrid">
                            <div className="field col-12">
                                <label htmlFor="idusuario">Colaborador<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Dropdown className="w-full" autoComplete="none" id="idusuario" value={item.idusuario} options={usuariosLista} onChange={(e) => aoDigitar(e, 'idusuario', 'item')} optionLabel="valor"
                                          optionValue="id" required/>
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="field col-12">
                                <label htmlFor="idpais">Data Entrada<Obrigatorio>*</Obrigatorio> </label><br/>
                                <Calendar
                                    id="time24"
                                    value={item.data_entrada}
                                    onChange={(e) => aoDigitar(e, 'data_entrada', 'item')}
                                    hourFormat="24"
                                    className="w-full"
                                    showTime
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                        <div className="grid formgrid">
                            <div className="field col-12">
                                <label htmlFor="idpais">Data Saída</label><br/>
                                <Calendar
                                    id="time24"
                                    value={item.data_saida}
                                    onChange={(e) => aoDigitar(e, 'data_saida', 'item')}
                                    hourFormat="24"
                                    className="w-full"
                                    showTime
                                    dateFormat="dd/mm/yy"
                                />
                            </div>
                        </div>
                    </>
                )}
            </Dialog>

            <Dialog className='fluid' header='Geração de Relatório' style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTelaRelatorio} modal={true} visible={exibirGeracaoRelatorio} onHide={limparTela}>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Loja<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idloja" value={relatorio.idloja} options={lojasLista} onChange={(e) => aoDigitar(e, 'idloja', 'relatorio')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idloja">Colaborador<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown disabled={desabilitarColaborador} className="w-full" autoComplete="none" id="idusuario" value={relatorio.idusuario} options={usuariosPorLojaLista} onChange={(e) => aoDigitar(e, 'idusuario', 'relatorio')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12">
                        <label htmlFor="idpais">Mês<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Calendar value={relatorio.periodo} onChange={(e) => aoDigitar(e, 'periodo', 'relatorio')} view="month" dateFormat="mm/yy" />
                    </div>
                </div>
            </Dialog>

            <Dialog
                className='fluid'
                header={`Relatório de pontos - ${relatorioPontoParamentros.funcionario.nome}`}
                style={{ maxWidth: '95%', width: '95%' }}
                contentStyle={{ height: '100%', width: '100%' }}
                modal={true}
                visible={exibirRelatorioPonto}
                onHide={limparTela}
                closeOnEscape={false}
                maximizable
            >
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={relatorioPontoParamentros.dados} header={botoesRelatorio} tableStyle={{ width: '100%' }} footerColumnGroup={totais} showGridlines scrollable scrollHeight="flex">
                    {relatorioPontoParamentros.colunas.map((col, index) => {
                        if (col.canEdit) {
                            return (
                                <Column
                                    key={index}
                                    field={col.field}
                                    header={col.header}
                                    editor={(options) => editorDeCelula(options)}
                                    onCellEditComplete={aoCompletarEdicao}
                                />
                            );
                        } else {
                            return (
                                <Column
                                    key={index}
                                    field={col.field}
                                    header={col.header}
                                />
                            );
                        }
                    })}
                </DataTable>
            </Dialog>
        </Tabela>
    );
}

export default Ponto;