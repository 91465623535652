import 'primeflex/primeflex.css';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';

import 'primereact/resources/primereact.min.css';
import React, {useState} from 'react';
import {Route, Routes}   from "react-router-dom";

import LoginLayout     from "./layouts/LoginLayout";
import SistemaLayout   from "./layouts/SistemaLayout";
import Loja            from "./pages/cadastro/loja";
import Ponto           from "./pages/cadastro/ponto";
import RegistrarPonto  from "./pages/cadastro/registrar-ponto";
import Folga           from "./pages/cadastro/folga";
import Feriado         from "./pages/cadastro/feriado";
import Funcionalidade  from "./pages/configuracao/funcionalidade";
import Menu            from "./pages/configuracao/menu";
import Modulo          from "./pages/configuracao/modulo";
import Tela            from "./pages/configuracao/tela";
import Dashboard       from "./pages/dashboard";
import Esquecisenha    from "./pages/seguranca/esquecisenha";
import Logout          from "./pages/seguranca/logout";
import NovaSenha       from "./pages/seguranca/novasenha";
import Perfil          from "./pages/seguranca/perfil";
import Usuario         from "./pages/seguranca/usuario";
import Autenticacao    from "./pages/seguranca/autenticacao";
import FormasPagamento from "./pages/vendas/formaspagamento";
import Venda           from "./pages/vendas/venda";
import Caixa           from "./pages/vendas/caixa";
import TransacaoCaixa  from "./pages/vendas/transacaocaixa";
import VendaInterna    from "./pages/vendas/vendainterna";

import Context        from "./services/context";
import './styles/estilos.css';
import './styles/tema-pti.css';
import {MsalProvider} from "@azure/msal-react";


export default function App({pca}) {
    const [atualizarMenu, setAtualizarMenu] = useState(false);

    return (
        <Context.Provider value={false}>
            <MsalProvider instance={pca}>
                <Routes>
                    <Route path="/" element={<LoginLayout/>}/>
                    <Route path="/autenticacao" element={<Autenticacao pca={pca}/>}/>
                    <Route path="/login" element={<LoginLayout/>}/>
                    <Route path="/resetar-senha" element={<SistemaLayout telaRestrita={false}><NovaSenha/></SistemaLayout>}/>
                    <Route path="/esqueci-senha" element={<SistemaLayout telaRestrita={false}><Esquecisenha/></SistemaLayout>}/>
                    <Route path="/logout" element={<SistemaLayout telaRestrita={false}><Logout/></SistemaLayout>}/>
                    <Route path="/sistema" element={<SistemaLayout><Dashboard/></SistemaLayout>}/>
                    <Route path="/seguranca/perfil" element={<SistemaLayout><Perfil/></SistemaLayout>}/>
                    <Route path="/seguranca/usuario" element={<SistemaLayout><Usuario/></SistemaLayout>}/>
                    <Route path="/configuracao/modulo" element={<SistemaLayout><Modulo/></SistemaLayout>}/>
                    <Route path="/configuracao/tela" element={<SistemaLayout><Tela/></SistemaLayout>}/>
                    <Route path="/configuracao/funcionalidade" element={<SistemaLayout><Funcionalidade/></SistemaLayout>}/>
                    <Route path="/configuracao/menu" element={<SistemaLayout><Menu atualizarMenu={atualizarMenu}/></SistemaLayout>}/>
                    <Route path="/cadastro/loja" element={<SistemaLayout><Loja/></SistemaLayout>}/>
                    <Route path="/cadastro/ponto" element={<SistemaLayout><Ponto/></SistemaLayout>}/>
                    <Route path="/cadastro/registrar-ponto" element={<SistemaLayout><RegistrarPonto/></SistemaLayout>}/>
                    <Route path="/cadastro/folga" element={<SistemaLayout><Folga/></SistemaLayout>}/>
                    <Route path="/cadastro/feriado" element={<SistemaLayout><Feriado/></SistemaLayout>}/>
                    <Route path="/vendas/formas-pagamento" element={<SistemaLayout><FormasPagamento/></SistemaLayout>}/>
                    <Route path="/vendas/venda" element={<SistemaLayout><Venda/></SistemaLayout>}/>
                    <Route path="/vendas/caixa" element={<SistemaLayout><Caixa/></SistemaLayout>}/>
                    <Route path="/vendas/transacoes-caixa" element={<SistemaLayout><TransacaoCaixa/></SistemaLayout>}/>
                    <Route path="/vendas/vendas-internas" element={<SistemaLayout><VendaInterna/></SistemaLayout>}/>
                </Routes>
            </MsalProvider>
        </Context.Provider>
    );
}
